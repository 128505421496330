import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {useLocation, useParams, useHistory} from 'react-router-dom';
import {FormattedMessage, injectIntl} from 'react-intl';
import ImageLazy from '../../../ui/ImageLazy';
import {HostUrl} from '../../../services/core/axios-service';
import speakerImg from '../../../assets/images/defaultImages/speaker.png';
import './SpeakerCard.scss';

const SpeakerCard = observer(props => {
  const {speaker, fullImageLink, sessionsAvailable} = props;
  const location = useLocation();
  const history = useHistory();
  const {eventId, speakerId} = useParams();

  const openAttendeeCardOfSpeaker = () => {
    history.push({
      pathname: `/event/${eventId}/attendees/${speaker.id}`,
      state: {
        background: location.state.background,
        page: 'attendees',
        title: speaker.name,
      },
    });
  };

  return speaker ? (
    <div className="speaker-card">
      <div className="speaker-card__details">
        <div className="speaker-card__img">
          {speaker.profilePhoto ? (
            <ImageLazy
              src={fullImageLink ? speaker.profilePhoto : `${HostUrl}/${speaker.profilePhoto}`}
              errorSrc={speakerImg}
              alt={speaker.name}
              onClick={() => (speakerId === speaker.id ? openAttendeeCardOfSpeaker() : props.openSpeaker())}
              width={100}
              height={100}
            />
          ) : (
            <img aria-hidden src={speakerImg} alt={speaker.name} width={100} height={100} />
          )}
        </div>
        <div className="speaker-card__user-info">
          <p className="speaker-card__user-label">
            <FormattedMessage id="speaker.card.label" />
          </p>
          {speakerId === speaker.id ? (
            <h4 className="speaker-card__user-name" onClick={() => openAttendeeCardOfSpeaker()} aria-hidden>
              {(Object.prototype.hasOwnProperty.call(speaker, 'name') ? `${speaker.name} ` : null) +
                (Object.prototype.hasOwnProperty.call(speaker, 'surname') ? `${speaker.surname}` : null)}
            </h4>
          ) : (
            <h4 className="speaker-card__user-name" onClick={() => props.openSpeaker()} aria-hidden>
              {(Object.prototype.hasOwnProperty.call(speaker, 'name') ? `${speaker.name} ` : null) +
                (Object.prototype.hasOwnProperty.call(speaker, 'surname') ? `${speaker.surname}` : null)}
            </h4>
          )}
          {speaker.jobTitle || speaker.company ? (
            <div className="speaker-card__user-company">
              {speaker.company}
              {speaker.company && speaker.jobTitle ? ', ' : ''}
              {speaker.jobTitle}
            </div>
          ) : null}
          {speaker.some ? (
            <div className="speaker-card__socials">
              {Object.keys(speaker.some).map(social => {
                if (speaker.some[social]) {
                  return (
                    <a
                      key={social}
                      rel="noopener noreferrer"
                      target="_blank"
                      href={speaker.some[social]}
                      className={`ui ${social} circular icon button`}
                    >
                      <i className={`${social} icon`} />
                    </a>
                  );
                }
                return null;
              })}
            </div>
          ) : null}
        </div>
      </div>
      <div className="speaker-card__sessions">
        <div className="speaker-card__sessions-wrapper" aria-hidden onClick={() => props.openSpeaker()}>
          <span className="speaker-card__sessions-count">{sessionsAvailable.length} </span>
          <FormattedMessage id="speaker.card.sessions" />
        </div>
      </div>
    </div>
  ) : null;
});

SpeakerCard.propTypes = {
  speaker: PropTypes.oneOfType([PropTypes.object]),
  sessionsAvailable: PropTypes.oneOfType([PropTypes.array]),
  fullImageLink: PropTypes.bool,
  openSpeaker: PropTypes.func,
};

SpeakerCard.defaultProps = {
  sessionsAvailable: [],
  fullImageLink: false,
  openSpeaker: () => {},
};

export default injectIntl(SpeakerCard);
